var VideoController = {
  $trigger: $('.js-video-insert'),
  dataVideoEmbed: 'data-video-embed',
  loadingClass: 'is-loading',

  init: function() {
    this.$trigger.on('click', this.handleClick);
  },

  handleClick: function() {
    var _ = VideoController,
        embedCode = $($(this).find('.js-video-code')).html(),
        $image = $(this).parent().find('img'),
        iframeWidth = $image.width(),
        trigger = this,
        src = embedCode.attr('src'),
        newSrc = src + 'autoplay=1',
        iframeHeight = $image.height();

    console.log(embedCode);

    embedCode.attr('src', newSrc);

    $(this).addClass(_.loadingClass);

    embedCode.css({
      'width': iframeWidth,
      'height': iframeHeight
    }).addClass('c-video__iframe')
      .insertAfter($image);

    $image.remove();

    embedCode.load(function() {
      embedCode.addClass('is-loaded');
      $(trigger).addClass('is-loaded');
    });
  }
};

module.exports = VideoController;
