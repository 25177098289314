$('.c-plan-wrapper').slick({
  infinite: true,
  speed: 300,
  slide: 'article',
  mobileFirst: true,
  dots: false,
  arrows:false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 641,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

/*
641px, 880px 
 881px, 1024px */

$(window).load(function() {
	$('.c-plan-next-button').on('click', function () {
	    $('.c-plan-wrapper').slick('next');
	});
	$('.c-plan-prev-button').on('click', function () {
	    $('.c-plan-wrapper').slick('prev');
	});
});