
/* globals $ */
/* jshint esnext:true */

'use strict';

require('../../node_modules/salvattore/src/salvattore');

$(document).ready(function () {
  var VideoController = require('./modules/videos');
  require('./modules/foundation');
  require('./modules/goldstone');
  require('./modules/sliders');

  VideoController.init();
});
