var $videoSlider = $('.js-video-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows:false
});

$('.js-video-slider-next').on('click', function () {
    $videoSlider.slick('next');
});

$('.js-video-slider-prev').on('click', function () {
    $videoSlider.slick('prev');
});

$imageSlider = $('.js-image-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  asNavFor: '.js-image-slider-nav'
});

$imageSliderNav = $('.js-image-slider-nav').slick({
  slidesToShow: 6,
  slidesToScroll: 1,
  focusOnSelect: true,
  dots: false,
  arrows: false,
  asNavFor: '.js-image-slider'
});


$('.js-image-slider-next').on('click', function () {
    $imageSlider.slick('next');
});

$('.js-image-slider-prev').on('click', function () {
    $imageSlider.slick('prev');
});
