/*jshint node:true*/
/* globals $, document */

'use strict';

require('../../lib/foundation/js/foundation/foundation');
require('../../lib/foundation/js/foundation/foundation.interchange');
require('../../lib/foundation/js/foundation/foundation.equalizer');
require('../../lib/foundation/js/foundation/foundation.accordion');
require('../../lib/foundation/js/foundation/foundation.tooltip');
require('../../lib/foundation/js/foundation/foundation.reveal');

$(document).foundation();
$(document).foundation('interchange', 'reflow');
